import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { IAdministratorAppRoleAssignment } from '../types/admins';
import { ICreateAppRoleAssignment } from '../types/applications';
import { IUserDetails } from '../types';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    private readonly http = inject(HttpClient);
    private readonly baseUrl: string = 'api/users';

    public assignUserToApplication(data: ICreateAppRoleAssignment): Observable<void> {
        return this.http.post<void>(`${this.baseUrl}/${data.userEmail}/appRoleAssignments`, data);
    }

    public removeUserFromRole(userId: string, roleAssignmentId: string, applicationId: string): Observable<any> {
        return this.http.delete(`${this.baseUrl}/${userId}/appRoleAssignments/${roleAssignmentId}/${applicationId}`);
    }
    
    public getAdministratorsForApplication(applicationId: string): Observable<IAdministratorAppRoleAssignment> {
        return this.http.get<IAdministratorAppRoleAssignment>(`api/admins/${applicationId}`);
    }

    public getUserDetails(email: string): Observable<IUserDetails> {
        return this.http.get<IUserDetails>(`${this.baseUrl}/${email}`);
    }
}
